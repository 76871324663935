@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBottomWrapperText from global;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.finePrint {
  composes: marketplaceModalHelperLink from global;
  padding: 3px 0 5px 0;
  text-align: left;
}

.privacyLink,
.termsLink {
  composes: marketplaceModalHelperLink from global;
  color: var(--marketplaceColor);

  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: underline;
    cursor: pointer;
  }
}

.poppHeader {
  position: relative;
  border-bottom: 1px solid rgb(221, 221, 221);
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 20px 0px;
  align-items: center;
}

.popupCloseIcon {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.popupContent {
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  gap: 10px;
  & p {
    margin: 0px;
  }
}


.popupContainer {
  position: absolute;
  height: auto;
  width: 400px;
  padding:20px;
  max-width: 100%;
  background-color: var(--colorWhite);
  box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px 0px;
  border-radius: 12px;
  bottom: 25px;
  left: 0;
  transform: none;
  z-index: 1;
  @media (--viewportLarge) {
    transform: translateX(50%);
  }
}

.generalTermsText {

  margin-left: 24px;
  font-size: 14px;
  position: relative;


  @media (--viewportLarge) {
    margin-left: 0px;
  }
}
