@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.userFieldsWrapper {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.defaultUserFields {
  &.columnLeft {
    @media (--viewportMedium) {
      flex-basis: 50%;
      padding-right: 10px;
    }
  }
}

.name {
  display: flex;
  justify-content: space-between;
}

.row {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 24px;
  }
}

.firstNameRoot {
  width: calc(40% - 9px);
  margin-bottom: 24px;
}

.lastNameRoot {
  width: calc(60% - 9px);
  margin-bottom: 24px;
}

.street {
  width: calc(70% - 9px);
}

.addressCity {
  width: calc(70% - 9px);
}

.addressPostalcode {
  width: calc(30% - 9px);
}

.streetnumber {
  width: calc(30% - 9px);
}

.addressCountry {
  margin-bottom: 24px;
}
.addressCity {
  margin-bottom: 24px;
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.formMargins {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.companyName {
  composes: formMargins;
}

.companyRegistrationNumber {
  composes: formMargins;
}

.nav {
  margin-bottom: 15px;
  text-align: center;
}

.dot {
  color: var(--marketplaceColor);
  cursor: pointer;
  font-size: 36px;
  line-height: 1;
  margin: 0 15px;
  opacity: 0.4;
  text-shadow: none;
  transition: opacity 1s ease, text-shadow 1s ease;
  will-change: opacity, text-shadow;
}

.active {
  color: var(--marketplaceColor);
  opacity: 1;
  text-shadow: 0 0px 8px;
}

.buttonSegment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  column-gap: 15px;
  padding-bottom: 20px;
  padding-top: 30px;
}

.buttonSegmentCenter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
  padding-bottom: 20px;
  padding-top: 30px;
  max-width: calc(100vw - 48px);
}

.nextBtn,
.backBtn {
  width: auto !important;
  padding: 0px 48px;
  @media (--viewportMedium) {
    padding: 0px 64px;
  }
}

.backBtn {
  background-color: white !important;
  color: var(--marketplaceColor) !important;
  border: 1px solid var(--marketplaceColor);
}

.pricingContainer {
  /* min-width: 60vw; */
  max-width: 100%;
}

.pricingSegment {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  @media (--viewportLarge) {
    align-items: stretch;
    flex-direction: row;
  }
}

.pricingCard {
  width: 30%;
  max-width: 100%;
  min-width: 100%;
  border-radius: 28px;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px 0px;
  background-color: var(--colorWhite);
  padding: 24px;
  display: flex;
  flex-direction: column;
  @media (--viewportLarge) {
    min-width: 350px;
    max-width: 400px;
  }
}

.spacer {
  flex-grow: 1;
  height: 20px;
}

.pricingHeading {
  font-size: 18px;
  font-weight: bold;
}

.pricingPrice {
  margin-top: 25px;
  font-size: 28px;
  font-weight: bolder;
}

.pricingSubHeading {
  font-size: 16px;
  margin-top: 18px;
  color: #777;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--colorGrey100);
}

.checksSegment {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 20px;
}

.checkContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.checkIcon {
  font-size: 20px;
}

.checkText {
  font-size: 16px;
  font-weight: normal;
}

.userTypeCard {
  width: 30%;
  max-width: 100%;
  min-width: 100%;
  border-radius: 28px;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px 0px;
  background-color: var(--colorWhite);
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (--viewportLarge) {
    min-width: 350px;
    max-width: 400px;
  }
}

.activeUserTypeCard {
  background-color: var(--marketplaceColorLight);
  .userTypeHeading {
    color: white !important;
  }
}

.userTypeHeading {
  font-size: 24px;
}

.userTypeIcon {
  width: 230px;
  background-color: var(--colorWhite);
  margin-bottom: 20px;
}

.userTypeBtn {
  width: auto;
  padding: 10px 60px;
}

.termsWrapper {
  margin-top: 24px;
}

/* title for steps and forms */

.align {
  text-align: center;
  justify-self: start;

  @media (--viewportMedium) {
    text-align: center;
    justify-self: center;
  }
}

.title {
  composes: align;
  &h2,
  &h1 {
    color: var(--marketplaceColor);
  }
  padding-top: 0px;
}

.description {
  composes: align;
}

.sectionDetails .description {
  font-size: 16px;
  line-height: 22.4px;
  color: var(--colorGrey500);
  padding-bottom: 15px;
}

.innerForm {
  max-width: 500px;
  min-width: 100%;
  border-radius: 28px;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px 0px;
  background-color: var(--colorWhite);
  padding: 40px;
  @media (--viewportMedium) {
    min-width: 460px;
  }
}
